// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"

import './src/global.css'

// normalize CSS across browsers
import "./src/normalize.css"
// custom CSS styles
import "./src/style.css"

import React from "react";
import { MDXProvider } from "@mdx-js/react";
import CodeBlock from './src/components/code_block'

const components = {
	pre: CodeBlock,
	wrapper: ({ children }) => <>{children}</>
};

export const wrapRootElement = ({ element }) => {
	return <MDXProvider components={components}>{element}</MDXProvider>;
};
